<template>
  <footer class="footer">
    <p>{{ this.lang.title }}</p>
    <p v-if="isDemo" class="fw-bold fs-6">{{ this.lang.demo }}</p>
  </footer>
</template>

<script>
export default {
  name: 'BottomFooter',
  data(){
    return{
      lang:{
        title:""
      },
      langmode : "ENG",
      isDemo: false,
    }
  },
  mounted() {
    if (localStorage.isDemo == "1") {
      this.isDemo = true;
    }
  },
  methods:{
    async langchange(picker) {
      console.log(picker)
      console.log(this.data)
      const response = await fetch("/footer.json");
      const data = await response.json();
      this.data = data
      this.lang = this.data[picker]
    }
  }
};
</script>

<style scoped>
.footer {
  background-color: #f8f9fa;
  text-align: center;
  padding: 20px 0;
  position: fixed;
  width: 100%;
  bottom: 0;
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  z-index:100;
  font-family: 'Century Gothic';
}
.footer p {
  margin: 0;
  font-size: 14px;
  color: #6c757d;
}
</style>
