<template>
    <div class="layout">
        <div class="overlay" v-if="loading"></div>
        <div style="z-index: 105 !important;">
            <SideNavbar @button-click="langchange"></SideNavbar>
        </div>
        <div class="regdevice_contents">
            <div class="regdevice_header">
                Manage Devices
            </div>
            <div class="dashboard_role">
                Current Role : {{ this.currentRole }}
            </div>
            <div class="regdevice_secondary_container">
                <p class="regdevice_secondary_header">Enter Customer Information</p>
                <p class="contents_header_text">Please enter customer Mobile Number (MSISDN) to search for customer
                    device record.</p>
            </div>
            <div class="regdevice_container">
                <form @submit.prevent="checkDevice">
                    <div class="form-group">
                        <label class="input_header_style" for="msisdn">MSISDN</label>
                        <input type="tel" class="form-control custom_input_form" id="msisdn"
                            placeholder="Enter customer MSISDN" v-model="msisdn" required />
                    </div>
                    <div class="text-center">
                        <button type="submit" class="reg_btn">Check</button>
                        <div v-if="tryagainWarning" class="warning-msg text-center">Unable to find device, please try
                            again.</div>
                    </div>
                </form>
            </div>
        </div>
        <div class="loading-card" v-if="loadingCommand">
            <div class="loading-content">
                <div class="loading-text">
                    <img src="../assets/loading.gif" class="img_style">
                    <p class="notiftext_title">Loading device information</p>
                </div>
            </div>
        </div>
        <!--Access denied-->
        <div class="loading-card" v-if="page_unavailable">
                <div class="loading-content">
                    <div class="loading-text">
                        <img src="../assets/multiply.png" class="img_style_denied_perms" style="margin-left: 15px;">
                        <p class="notiftext_title_denied_perms" style="margin-left: 20px;">{{
                            this.lang.popup_card_access_denied[0] }}</p>
                        <p class="notiftext_denied_perms">{{ this.lang.popup_card_access_denied[1] }}</p>
                        <button type="submit" class="mandev_btn text-center"
                            style="margin-left: 15px; margin-top: 20px;" @click="goBack">{{
                                this.lang.popup_card_access_denied[2] }}</button>
                    </div>
                </div>
            </div>
    </div>
    <BottomFooter ref="BottomFooterRef"></BottomFooter>
</template>

<script>
import SideNavbar from './SideNavbar.vue';
import BottomFooter from './BottomFooter.vue';

export default {
    components: {
        SideNavbar,
        BottomFooter
    },
    data() {
        return {
            page_unavailable: false,
            msisdn: '',
            loading: false,
            loadingCommand: false,
            success: false,
            tryagainWarning: false,
            currentRole: localStorage.role,
            lang: {
                popup_card_access_denied: [
                    "Access Denied",
                    "You do not have permission to access this feature, contact your admin for further measures",
                    "OK"
                ]
            }
        };
    },
    methods: {
        checkLogin() {
            //Enable overlay
            this.loading = true;
            //Prep API
            const data = JSON.stringify({
                token: localStorage.token
            });
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: process.env.VUE_APP_BACKEND_URL+'/v1/login/validity-check',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            //Run API
            axios.request(config)
                .then((response) => {
                    if (response.status === 200) {
                        this.loading = false;
                    }
                    else {
                        this.page_unavailable = true;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.page_unavailable = true;
                });
        },
        checkDevice() {
            this.loading = true;
            this.loadingCommand = true;

            // Run API Command For Base Device Information
            const axios = require('axios');
            const data_input = JSON.stringify({
                token: localStorage.token,
                MSISDN: this.msisdn
            });
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: process.env.VUE_APP_BACKEND_URL+'/v1/dashboard/MSISDN_lookup',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                data: data_input
            };

            axios.request(config)
                .then((response) => {
                    console.log(response.data["200"][0]);
                    if (response.status == 200) {
                        const dataEntry = response.data["200"][0];
                        localStorage.selectedname = dataEntry[3];
                        localStorage.selectedmodel = dataEntry[1];
                        localStorage.selectedstorage = dataEntry[2];
                        localStorage.selected = dataEntry[7];
                        localStorage.selectedms = dataEntry[11];
                        localStorage.selectedcontact = dataEntry[16];
                        localStorage.contractStatus = dataEntry[15];
                        localStorage.tenure_device = dataEntry[4];
                        localStorage.startRegDate = dataEntry[5];
                        localStorage.selectedLastTrigger = dataEntry[10];
                        localStorage.contract_status = dataEntry[15]
                        //this.getUpdatedTimes();

                        setTimeout(() => {
                            this.$router.replace('/device-data-list')
                        }, 3000);
                    }
                    else {
                        this.loading = false;
                        this.loadingCommand = false;
                        this.tryagainWarning = true;
                        return;
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false;
                    this.loadingCommand = false;
                    this.tryagainWarning = true;
                    return;
                });


            // Route to device list
            console.log("Checking Device");

        },
        getUpdatedTimes() {
            //Manage date
            var dateObj = new Date(localStorage.startRegDate);

            // Extract date components
            var dayOfWeek = dateObj.toLocaleString('en-us', { weekday: 'short' }); // Short day name
            var dayOfMonth = ('0' + dateObj.getDate()).slice(-2); // Zero-padded day of the month
            var month = dateObj.toLocaleString('en-us', { month: 'short' }); // Short month name
            var year = dateObj.getFullYear(); // Full year
            var minutes = ('0' + dateObj.getMinutes()).slice(-2); // Zero-padded minutes
            var period = dateObj.getHours() >= 12 ? 'PM' : 'AM'; // Determine AM/PM

            // Convert hours to 12-hour format
            var hours12 = dateObj.getHours() % 12 || 12; // Convert 0 to 12 for midnight

            // Format the date in "%a, %d %b %Y %I:%M%p" format
            var formattedDate = `${dayOfWeek}, ${dayOfMonth} ${month} ${year} ${hours12}:${minutes}${period}`;

            //Run API Command for live data
            const axios = require('axios');
            let config = {
                method: 'POST',
                maxBodyLength: Infinity,
                url: process.env.VUE_APP_BACKEND_URL+'/v1/dashboard/device/trigger-time-lookups',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({
                    token: localStorage.token,
                    serial_number: localStorage.selected,
                    tenure: localStorage.selectedLastTrigger,
                    start_date: formattedDate
                })
            };

            axios.request(config)
                .then((response) => {
                    localStorage.selectedLastTrigger = response.data.last_command_update;
                    localStorage.contract_status = response.data.contract_left_tenure;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        goBack() {
            this.$router.replace('/dashboard'); // Go back one step in the history
        },
        goDashboard() {
            this.$router.replace('/dashboard');
        },
        async langchange(picker) {
            console.log(picker)
            this.$refs.BottomFooterRef.langchange(picker)
            console.log(this.data)
        }
    },
    mounted() {
        this.checkLogin();
    }
}
</script>

<style scoped>
.layout {
    display: flex;
}

.regdevice_contents {
    flex: 1;
    padding-left: 16px;
    font-family: 'Century Gothic';
}

.regdevice_header {
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 24px;
    margin-top: 5px;
}

.regdevice_secondary_header {
    font-family: 'Century Gothic Bold';
    font-weight: bold;
    font-size: 20px;
    margin-top: 15px;
}

.regdevice_secondary_container {
    margin-top: 30px;
}

.regdevice_container {
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    margin-top: 100px;
    max-height: 680px;
    overflow-y: auto;
}

.contents_header_text {
    font-size: 14px;
    margin-top: -15px;
}

.input_header_style {
    font-family: 'Century Gothic Bold';
    font-size: 12px;
    color: #013042;
    margin-bottom: 2px;
}

.reg_btn {
    text-align: center;
    margin-top: 30px;
    width: 140px;
    height: 30px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.reg_btn:hover {
    filter: brightness(90%);
}

.custom_input_form {
    background-color: #ffffff;
    height: 35px;
    width: 480px;
    text-decoration: none solid rgba(0, 0, 0, 0.87);
    border-color: rgba(1, 48, 66, 0.2) !important;
    word-spacing: 0px;
    letter-spacing: 0.131118px;
    font-size: 12px !important;
    box-sizing: border-box;
    outline: rgba(0, 0, 0, 0.87) none 0px;
}

.custom_input_form:focus {
    outline: none !important;
    box-shadow: none !important;
    border-color: rgba(1, 48, 66, 0.8) !important;
    border-width: 2.5px;
    background-color: #ffffff;
    color: #000000;
    font-size: 12px;
}

::placeholder {
    font-size: 12px;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent overlay */
    z-index: 9998;
    /* Below the loading content */
}

.warning-msg {
    color: red;
    font-weight: bold;
    font-size: 12px;
    margin-top: 10px;
}

.loading-card {
    position: fixed;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 28px;
    filter: brightness(98%);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-content {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.loading-text {
    justify-items: center;
    display: grid;
    font-family: 'Century Gothic', sans-serif;
    text-align: center;
}

.img_style_denied_perms {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
    left: 10px;
}

.notiftext_title_denied_perms {
    font-family: 'Century Gothic Bold';
    font-size: 16px;
    color: #000000;
    margin-bottom: 2px;
}

.notiftext_denied_perms {
    font-family: 'Century Gothic';
    text-align: center !important;
    font-size: 12px;
    color: #000000;
    margin-bottom: 8px;
}

.mandev_btn {
    text-align: center;
    width: 150px;
    height: 30px;
    margin-bottom: 5px;
    font-family: 'Century Gothic Bold', sans-serif;
    font-size: 10px;
    border: none;
    border-radius: 28px;
    padding: 0.3vw;
    background-color: #ffd600;
    color: #013042;
    transition: background-color 0.3s ease;
    font-weight: 700;
}

.mandev_btn:hover {
    filter: brightness(90%);
}


</style>